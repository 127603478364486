var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BCard', {
    staticClass: "border mb-0",
    staticStyle: {
      "width": "fit-content",
      "height": "fit-content"
    },
    attrs: {
      "body-class": "py-1 px-2"
    }
  }, [_c('div', {
    staticClass: "d-flex-between font-weight-bolder mb-25"
  }, [_vm._v(" " + _vm._s(_vm.title) + " "), _c('div', {
    staticClass: "d-flex gap-2"
  }, [_c('BButton', {
    staticClass: "p-50",
    attrs: {
      "variant": "flat-dark",
      "disabled": _vm.disabledCopy
    },
    on: {
      "click": function click($event) {
        return _vm.copyApiKey(_vm.apiKey);
      }
    }
  }, [_c('feather-icon', {
    staticClass: "cursor-pointer",
    attrs: {
      "icon": "CopyIcon"
    }
  })], 1), _c('BButton', {
    staticClass: "p-50",
    attrs: {
      "variant": "flat-info"
    },
    on: {
      "click": _vm.toggleApiKey
    }
  }, [_c('feather-icon', {
    staticClass: "cursor-pointer",
    attrs: {
      "icon": _vm.showApiKey ? 'EyeIcon' : 'EyeOffIcon'
    }
  })], 1)], 1)]), _c('kbd', {
    staticClass: "text-dark",
    staticStyle: {
      "font-family": "SFMono-Regular,Menlo,Monaco,Consolas,monospace !important"
    }
  }, [_vm._v(" " + _vm._s(_vm.showApiKey ? _vm.apiKey : _vm.hideApiKey(_vm.apiKey)) + " ")])]);
}
var staticRenderFns = []

export { render, staticRenderFns }