<template lang="">
  <BCard
    style="width: fit-content; height: fit-content"
    class="border mb-0"
    body-class="py-1 px-2"
  >
    <div class="d-flex-between font-weight-bolder mb-25">
      {{ title }}
      <div class="d-flex gap-2">
        <BButton
          variant="flat-dark"
          class="p-50"
          :disabled="disabledCopy"
          @click="copyApiKey(apiKey)"
        >
          <feather-icon
            class="cursor-pointer"
            icon="CopyIcon"
          />
        </BButton>
        <BButton
          variant="flat-info"
          class="p-50"
          @click="toggleApiKey"
        >
          <feather-icon
            class="cursor-pointer"
            :icon="showApiKey? 'EyeIcon' : 'EyeOffIcon'"
          />
        </BButton>
      </div>
    </div>
    <kbd
      class="text-dark"
      style="font-family: SFMono-Regular,Menlo,Monaco,Consolas,monospace !important"
    >
      {{ showApiKey ? apiKey : hideApiKey(apiKey) }}
    </kbd>
  </BCard>
</template>
<script>
import { BCard, BButton } from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import { useClipboard } from '@vueuse/core'

export default {
  components: {
    BCard,
    BButton,
  },
  props: {
    apiKey: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      required: true,
    },
  },
  setup() {
    const showApiKey = ref(false)
    function toggleApiKey() { showApiKey.value = !showApiKey.value }
    function hideApiKey(apiKey) {
      return apiKey.replace(/./g, '*')
    }
    const { copy } = useClipboard()
    const disabledCopy = ref(false)
    function copyApiKey(apiKey) {
      disabledCopy.value = true
      setTimeout(() => {
        disabledCopy.value = false
      }, 1000)

      copy(apiKey)
        .then(() => {
          this.$root.toastSuccess('reservation.sms.copySuccess')
        })
        .catch(() => {
          this.$root.toastError('reservation.sms.copyFail')
        })
    }
    return {
      toggleApiKey, showApiKey, hideApiKey, copyApiKey, disabledCopy,
    }
  },
}
</script>
